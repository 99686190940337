import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

config.autoAddCss = false;

export const IconBadge = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_450_8558)">
        <path
          d="M57.5 29.975L51.4 23L52.25 13.775L43.225 11.725L38.5 3.75L30 7.4L21.5 3.75L16.775 11.725L7.75 13.75L8.6 23L2.5 29.975L8.6 36.95L7.75 46.2L16.775 48.25L21.5 56.25L30 52.575L38.5 56.225L43.225 48.25L52.25 46.2L51.4 36.975L57.5 29.975ZM47.625 33.675L46.225 35.3L46.425 37.425L46.875 42.3L42.125 43.375L40.025 43.85L38.925 45.7L36.45 49.9L32 47.975L30 47.125L28.025 47.975L23.575 49.9L21.1 45.725L20 43.875L17.9 43.4L13.15 42.325L13.6 37.425L13.8 35.3L12.4 33.675L9.175 30L12.4 26.3L13.8 24.675L13.575 22.525L13.125 17.675L17.875 16.6L19.975 16.125L21.075 14.275L23.55 10.075L28 12L30 12.85L31.975 12L36.425 10.075L38.9 14.275L40 16.125L42.1 16.6L46.85 17.675L46.4 22.55L46.2 24.675L47.6 26.3L50.825 29.975L47.625 33.675Z"
          fill="#04AF00"
          fillOpacity="0.6"
        />
        <path
          d="M25.2251 34.375L19.4251 28.55L15.7251 32.275L25.2251 41.8L43.5751 23.4L39.8751 19.675L25.2251 34.375Z"
          fill="#04AF00"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_450_8558">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
