import Nav from "./nav";
import Footer from "./footer";

const Layout = ({ children, categories, seo }) => (
  <>
    <div className={`overflow-x-hidden`}>
      <Nav categories={categories} />
      {children}
      <Footer categories={categories} />
    </div>
  </>
);

export default Layout;
