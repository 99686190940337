import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

config.autoAddCss = false;

export const IconLocation = () => {
  return (
    <span className={`text-2xl`}>
      <FontAwesomeIcon icon={faLocationDot} />
    </span>
  );
};
