import React, { useEffect } from "react";
import { DropdownArrow } from "./dropdown-arrow";
import Link from "next/link";
import { IconPhone } from "./icon-phone";
import { IconShipping } from "./icon-shipping";
import { IconMenu } from "./icon-menu";
import { CategoriesList } from "./category-list";
import { Logo, LogoNav } from "./logo-nav";
import { Search } from "./search";
import { useRouter } from "next/router";

const Nav = ({ categories }) => {
  const [showMegaMenu, setShowMegaMenu] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  categories = categories
    ? categories

        .filter((c) => c?.attributes?.parents?.data?.length === 0)
        .sort((a, b) => {
          return a.attributes.order && b.attributes.order
            ? a.attributes.order - b.attributes.order
            : 1;
        })
    : [];

  const megaMenuRef = React.useRef();

  const router = useRouter();

  useEffect(() => {
    setShowMegaMenu(false);
    setShowMobileMenu(false);
  }, [router]);

  return (
    <div className={`font-display`}>
      <nav className={`w-full bg-white`}>
        <div className={`border-gray-200 border-b`}>
          <ul className="container px-1 py-1 flex flex-row space-x-4 divide-x justify-end mx-auto">
            <li className={`font-bold lg:hidden`}></li>
            <li className={`flex-grow border-none`}></li>
            <li className={`font-bold border-none`}>
              <div className={`flex flex-row items-center space-x-3`}>
                <div className={`scale-[.80]`}>
                  <IconPhone fill={"gray"} />
                </div>
                <div>(800) 353-0906</div>
              </div>
            </li>
          </ul>
        </div>

        <div className={`visible lg:hidden mb-4 shadow-lg`}>
          <ul className="container px-1 py-1 flex flex-row space-x-4 divide-x items-center mx-auto">
            <li className={`flex-grow border-none`}>
              <Link href={`/`}>
                <a className={`block w-[120px]`}>
                  <LogoNav />
                </a>
              </Link>
            </li>
            <li
              className={`border-none justify-end text-right cursor-pointer`}
              onClick={(e) => setShowMobileMenu(!showMobileMenu)}
            >
              <IconMenu />
            </li>
          </ul>
        </div>

        {showMobileMenu && (
          <div className={`container px-1 py-1`}>
            <CategoriesList categories={categories} />
            <ul className={`mt-4 py-3 text-gray-700 border-t`}>
              <li className={`mt-1`}>
                <Link href={`/about`}>
                  <a className={`hover:underline`}>About Us</a>
                </Link>
              </li>
              <li className={`mt-1`}>
                <Link href={`/contact`}>
                  <a className={`hover:underline`}>Contact Us</a>
                </Link>
              </li>
              <li className={`mt-1`}>
                <Link href={`/legal/shipping-and-returns`}>
                  <a className={`hover:underline`}>
                    Shipping and Return Policy
                  </a>
                </Link>
              </li>
              <li className={`mt-1`}>
                <Link href={`/legal/payment-options`}>
                  <a className={`hover:underline`}>Payment Options</a>
                </Link>
              </li>
            </ul>
          </div>
        )}

        <div className={`shadow-lg`}>
          <div className={`hidden lg:block`}>
            <ul className="container px-1 py-0 flex flex-row space-x-7 justify-around mx-auto items-center font-bold uppercase">
              <li className={`lg:mr-8 lg:text-[24px] lg:text-[64px]`}>
                <Link href={`/`}>
                  <a className={`block w-[185px]`}>
                    <LogoNav />
                  </a>
                </Link>
              </li>
              <li className={`whitespace-nowrap`}>
                <Link href={`/self-contained-ratchet-straps`}>
                  <a className={`hover:underline`}>
                    Self Contained Ratchet Straps
                  </a>
                </Link>
              </li>
              <li className={`whitespace-nowrap`}>
                <Link href={`/spin-free-ratchet-straps`}>
                  <a className={`hover:underline`}>Spin Free Ratchet Straps</a>
                </Link>
              </li>
              <li className={`whitespace-nowrap`}>
                <span
                  className={`cursor-pointer`}
                  onMouseOver={() => {
                    setShowMegaMenu(true);
                  }}
                >
                  Shop By Categories <DropdownArrow />
                </span>
              </li>
              <li>
                <Link href={`/about`}>
                  <a className={`hover:underline`}>About</a>
                </Link>
              </li>
              <li>
                <Link href={`/contact`}>
                  <a className={`hover:underline`}>Contact</a>
                </Link>
              </li>
              <li className={`flex flex-row space-x-2 hidden xl:flex`}>
                <a href={`#`}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.000244141 12C0.000244141 5.37258 5.37283 0 12.0002 0C18.6277 0 24.0002 5.37258 24.0002 12C24.0002 18.6274 18.6277 24 12.0002 24C5.37283 24 0.000244141 18.6274 0.000244141 12ZM6.15024 9.975V18H8.70025V9.975H6.15024ZM6.00024 7.425C6.00024 8.25 6.60025 8.85 7.42524 8.85C8.25024 8.85 8.85025 8.25 8.85025 7.425C8.85025 6.6 8.25024 6 7.42524 6C6.67524 6 6.00024 6.6 6.00024 7.425ZM15.4502 18H17.8502V13.05C17.8502 10.575 16.3502 9.75 14.9252 9.75C13.6502 9.75 12.7502 10.575 12.5252 11.1V9.975H10.1252V18H12.6752V13.725C12.6752 12.6 13.4252 12 14.1752 12C14.9252 12 15.4502 12.375 15.4502 13.65V18Z"
                      fill="black"
                    />
                  </svg>
                </a>
                <a href={`#`}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.000244141 12C0.000244141 5.37258 5.37283 0 12.0002 0C18.6277 0 24.0002 5.37258 24.0002 12C24.0002 18.6274 18.6277 24 12.0002 24C5.37283 24 0.000244141 18.6274 0.000244141 12ZM16.5752 8.625C17.1002 8.55 17.5502 8.475 18.0002 8.25C17.7002 8.775 17.2502 9.225 16.7252 9.525C16.8752 13.05 14.3252 16.875 9.75025 16.875C8.40025 16.875 7.12525 16.425 6.00025 15.75C7.27525 15.9 8.62525 15.525 9.52525 14.85C8.40025 14.85 7.50025 14.1 7.20025 13.125C7.57525 13.2 7.95025 13.125 8.32525 13.05C7.20025 12.75 6.37525 11.7 6.37525 10.575C6.75025 10.725 7.12525 10.875 7.50025 10.875C6.45025 10.125 6.07525 8.7 6.75025 7.575C8.02525 9.075 9.82525 10.05 11.8502 10.125C11.4752 8.625 12.6752 7.125 14.2502 7.125C14.9252 7.125 15.6002 7.425 16.0502 7.875C16.6502 7.725 17.1752 7.575 17.6252 7.275C17.4752 7.875 17.1002 8.325 16.5752 8.625Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </li>

              <li className={`grow xl:flex`}>
                <div className="flex items-center justify-center w-full ">
                  <form className={`w-full`} method="GET">
                    <div className="relative">
                      <Search />
                    </div>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          ref={megaMenuRef}
          className={`${
            showMegaMenu ? "" : "hidden"
          } absolute w-full bg-white border-t z-10 shadow-md`}
          onMouseMove={() => setShowMegaMenu(true)}
          onMouseOut={() => setShowMegaMenu(false)}
          onClick={() => setShowMegaMenu(false)}
        >
          <div className={`container mx-auto px-1 py-4`}>
            <div className={`flex flex-wrap justify-between`}>
              <CategoriesList categories={categories} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
