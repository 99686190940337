import Link from "next/link";

export const CategoriesList = ({ categories }) => {
  return (
    <ul className={`grid lg:grid-cols-3 gap-x-32 gap-y-8`}>
      {categories.map((category, key) => {
        return (
          <li key={key}>
            <Link href={`/category/${category.attributes.slug}`}>
              <a className={`font-bold uppercase hover:underline`}>
                {category.attributes.name}
              </a>
            </Link>
            <ul className={`mt-4`}>
              {category?.attributes?.children?.data
                .sort((a, b) => {
                  return (
                    (a.attributes.order === null) -
                      (b.attributes.order === null) ||
                    +(a.attributes.order > b.attributes.order) ||
                    -(a.attributes.order < b.attributes.order)
                  );
                })
                .map((child, key) => {
                  return (
                    <li className={`mt-1`} key={key}>
                      <Link
                        href={`/category/${category.attributes.slug}/${child.attributes.slug}`}
                      >
                        <a className={`hover:underline sub text-gray-400`}>
                          {child.attributes.name}
                        </a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
