import React from "react";
import Link from "next/link";
import { IconPhone } from "./icon-phone";
import { IconLocation } from "./icon-location";
import { CategoriesList } from "./category-list";
import styles from "./footer.module.css";
import Image from "next/image";
import { IconBadge } from "./icon-badge";
import { LogoFooter } from "./logo-footer";
import Head from "next/head";
import Script from "next/script";

const Footer = ({ categories }) => {
  categories = categories
    ? categories
        .filter((c) => c?.attributes?.parents?.data?.length === 0)
        .sort((a, b) => {
          return a.attributes.order - b.attributes.order;
        })
    : [];

  return (
    <>
      <div className={``}>
        <div className={`hidden lg:block`}>
          <div className={`py-16 bg-[#f5f5f5] mt-8`}>
            <div className="container mx-auto pl-1 pr-1 relative font-display">
              <h3
                className={`uppercase font-bold text-[32px] border-b pb-8 mb-8 leading-none`}
              >
                We’ve Got YOU COVERED
              </h3>
              <section className={`grid grid-cols-3 gap-8 mt-8`}>
                <div
                  className={`bg-white px-6 py-8 flex flex-row items-center`}
                >
                  <span className={`mr-4`}>
                    <IconBadge />
                  </span>
                  <h4 className={`text-[16px] font-bold leading-none`}>
                    Most User Friendly Ratchets in the Industry
                  </h4>
                </div>
                <div
                  className={`bg-white px-6 py-8 flex flex-row items-center`}
                >
                  <span className={`mr-4`}>
                    <IconBadge />
                  </span>
                  <h4 className={`text-[16px] font-bold leading-none`}>
                    Over 75 year of combined experience in Service, Sales, and
                    Manufacturing of our products
                  </h4>
                </div>
                <div
                  className={`bg-white px-6 py-8 flex flex-row items-center`}
                >
                  <span className={`mr-4`}>
                    <IconBadge />
                  </span>
                  <h4 className={`text-[16px] font-bold leading-none`}>
                    Highest Rated Capacities in the Industry
                  </h4>
                </div>
              </section>
            </div>
          </div>
          <div className={`text-center mb-8 mt-16`}>
            <Image
              alt={`Ratchet Strap Assembly`}
              width={"928px"}
              height={"94px"}
              src={`https://ratchet-depot-uploads.s3.amazonaws.com/Banner_2_928x94_1_6181dfa0fd.jpg?updated_at=2022-10-13T23:16:55.283Z`}
            />
          </div>
          <div className="container mx-auto pl-1 pr-1 relative font-display py-10 pb-16">
            <div className={`flex flex-row border items-center py-6 px-16`}>
              <h3
                className={`font-display font-medium text-[24px] leading-none flex-grow`}
              >
                Sign up for Ratchet Depot emails and get special offers and
                deals delivered to your inbox.
              </h3>
              <span>
                <button
                  onClick={() => {
                    window.open("http://eepurl.com/icwCNX", "_blank");
                  }}
                  className={`bg-black text-white rounded-full leading-none px-9 py-5 uppercase font-bold`}
                >
                  Sign Up
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className={`w-full bg-[#201E50] text-white font-display`}>
          <div className={`container mx-auto px-1 pt-16`}>
            <div className={`grid grid-cols-4 pb-8 border-b border-gray-600`}>
              <div className={`hidden lg:block`}>
                <LogoFooter />
                <div className={`font-bold uppercase hover:underline mt-8`}>
                  Contact
                </div>
                <div className={`my-4`}>
                  <IconPhone fill={"white"} />
                </div>
                <p>
                  Toll Free: 1-800-353-0906
                  <br />
                  Local: 1 (864) 299-6672
                </p>
                <div className={`mt-4 mb-2`}>
                  <IconLocation />
                </div>
                <p>
                  1702 Piedmont Highway
                  <br />
                  Piedmont, SC 29673
                </p>
                <ul className={`mt-6 text-gray-400`}>
                  <li className={`mt-1`}>
                    <Link href={`/about`}>
                      <a className={`hover:underline`}>About Us</a>
                    </Link>
                  </li>
                  <li className={`mt-1`}>
                    <Link href={`/contact`}>
                      <a className={`hover:underline`}>Contact Us</a>
                    </Link>
                  </li>
                  <li className={`mt-1`}>
                    <Link href={`/legal/shipping-and-returns`}>
                      <a className={`hover:underline`}>
                        Shipping and Return Policy
                      </a>
                    </Link>
                  </li>
                  <li className={`mt-1`}>
                    <Link href={`/legal/payment-options`}>
                      <a className={`hover:underline`}>Payment Options</a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={`col-span-4 lg:col-span-3`}>
                <div className={styles.footer}>
                  <CategoriesList categories={categories} />
                </div>
              </div>
            </div>
            <div className={``}>
              <div className={`grid grid-cols-3 py-6`}>
                <ul className={`flex flex-row`}>
                  <li className={`mr-6 hidden lg:block`}>
                    <Link href={`/legal/privacy-policy`}>
                      <a className={`leading-none text-gray-400`}>
                        Privacy Policy
                      </a>
                    </Link>
                  </li>
                  <li className={`hidden lg:block`}>
                    <Link href={`/legal/terms-and-conditions`}>
                      <a className={`text-gray-400`}>Terms and Conditions</a>
                    </Link>
                  </li>
                </ul>
                <h3
                  className={`leading-none font-bold text-center uppercase text-[24px]`}
                >
                  Built in the USA
                </h3>
                <div
                  className={`leading-none text-right text-gray-400 hidden lg:block`}
                >
                  &copy; 2022 The Ratchet Depot, Inc. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
