import React from "react";

export const LogoNav = () => {
  return (
    <>
      <img
        className={`py-2`}
        src={`https://ratchet-depot-uploads.s3.amazonaws.com/Ratchet_Depot_Vector_Logo_1_0a7e59e7b7.svg?updated_at=2022-10-14T13:29:48.322Z`}
      />
    </>
  );
};
