import React from "react";

export const LogoFooter = () => {
  return (
    <>
      <div
        className={`w-[185px] h-[96px] block my-2`}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("https://ratchet-depot-uploads.s3.us-east-1.amazonaws.com/Ratchet%20Depot%20Vector%20Logo%201.svg")`,
        }}
      ></div>
    </>
  );
};
